@font-face {
  font-family: 'gradient';
  src: url('./medium.woff2');
}

body {
  margin: 0;    
  font-family: "gradient";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("https://arc.net/noise.png"); */
  background-attachment: fixed;
  font-weight: 500;
  line-height: 1.5;
  tab-size: 4;
  letter-spacing: 0px;
  background-color: black;
  color: rgb(190, 190, 190);
  word-break: break-all;
}